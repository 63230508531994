<template>
  <ContentComponent class="flex flex-col" >
    <template #message-top>
      <h1
        :class="{ [props.animations] : isVisible}"
        ref="element"
      >
        <slot name="top" />
      </h1>
    </template>

    <template #center>
      <slot name="content" />
    </template>

    <template #message-bottom>
      <h1
        :class="{ [props.animations] : isVisible}"
        ref="element"
      >
        <slot name="bot" />
      </h1>
    </template>
  </ContentComponent>
</template>

<script setup>
import ContentComponent from './../ContentsComponent.vue'
import { ref, onBeforeUnmount, onMounted, defineProps } from 'vue'

let observer = ref(null)
const isVisible = ref(false)
const element = ref(null)
const props = defineProps({
  text: String,
  animations: {
    type: String,
    default: 'animate__animated animate__bounce animate__delay-1s'
  }
})

onMounted(() => {
  observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          isVisible.value = true
          observer.unobserve(entry.target)
        }
      })
    },
    { threshold: 0.1 }
  )

  if (element.value) {
    observer.observe(element.value)
  }
})

onBeforeUnmount(() => {
  if (element.value) {
    observer.disconnect()
  }
})

</script>

<style scoped lang="scss">

</style>
