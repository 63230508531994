<template>
  <div class="flex justify-center items-center">
    <img
      :src="gifSrc"
      alt="GIF"
      width="400"
      height="400"
      class="max-w-full h-auto object-contain"
    />
  </div>
</template>

<script>
export default {
  name: 'ContentGif',
  props: {
    gifSrc: {
      type: String,
      required: true
    }
  }
}
</script>
