import HomeView from './../HomeView.vue'

export const homeRoute = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    component: HomeView
  }
]
